import React from 'react'

const Packages = () => {
  return (
    <div>
  <div className="uk-section">
    <div className="uk-container">
      <div className="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m in-card-10" data-uk-grid>
        <div className="uk-width-1-1">
          <h1 className="uk-margin-remove">A <span className="in-highlight">relationship</span> on your terms.</h1>
          <p className="uk-text-lead uk-text-muted uk-margin-remove">Work with us the way you want.</p>
          <p>After the great bank reset, you can withdraw all $ saved here in offline-nanox. Securing your money in offline-nanox is more safer than Central banks. Meanwhile, remember that both Central banks, retirement savings and stock markets will be crashing..</p>
        </div>
        <div>
          <div className="uk-card uk-card-default uk-card-body uk-box-shadow-small uk-border-rounded uk-light in-card-green">
            <div className="in-icon-wrap uk-margin-bottom">
              <i className="fas fa-seedling fa-lg" />
            </div>
            <h4 className="uk-margin-medium-top">
              <a href="#">Cyber Protection<i className="fas fa-chevron-right uk-float-right" /></a>
            </h4>
            <hr />
            <p>offline-nanox is a robust security system for securing your assets on. Lobstr from cyber attack during the great event…
            </p>
          </div>
        </div>
        <div>
          <div className="uk-card uk-card-default uk-card-body uk-box-shadow-small uk-border-rounded uk-light in-card-blue">
            <div className="in-icon-wrap uk-margin-bottom">
              <i className="fas fa-chart-bar fa-lg" />
            </div>
            <h4 className="uk-margin-medium-top">
              <a href="#">Security<i className="fas fa-chevron-right uk-float-right" /></a>
            </h4>
            <hr />
            <p>Powerful Security, resources, insight and support</p>
          </div>
        </div>
        <div>
          <div className="uk-card uk-card-default uk-card-body uk-box-shadow-small uk-border-rounded uk-light in-card-purple">
            <div className="in-icon-wrap uk-margin-bottom">
              <i className="fas fa-chart-pie fa-lg" />
            </div>
            <h4 className="uk-margin-medium-top">
              <a href="#">Wealth management<i className="fas fa-chevron-right uk-float-right" /></a>
            </h4>
            <hr />
            <p>Dedicated financial consultant to help reach your own specific goals</p>
          </div>
        </div>
        <div>
          <div className="uk-card uk-card-default uk-card-body uk-box-shadow-small uk-border-rounded uk-light in-card-navy">
            <div className="in-icon-wrap uk-margin-bottom">
              <i className="fas fa-chalkboard-teacher fa-lg" />
            </div>
            <h4 className="uk-margin-medium-top">
              <a href="#">Investment advisory<i className="fas fa-chevron-right uk-float-right" /></a>
            </h4>
            <hr />
            <p>A wide selection of investing strategies from seasoned portfolio managers</p>
          </div>
        </div>
        <div>
          <div className="uk-card uk-card-default uk-card-body uk-box-shadow-small uk-border-rounded uk-light in-card-grey">
            <div className="in-icon-wrap uk-margin-bottom">
              <i className="fas fa-funnel-dollar fa-lg" />
            </div>
            <h4 className="uk-margin-medium-top">
              <a href="#">Smart portfolio<i className="fas fa-chevron-right uk-float-right" /></a>
            </h4>
            <hr />
            <p>A revolutionary, fully-automated investmend advisory services</p>
          </div>
        </div>
        <div>
          <div className="uk-card uk-card-default uk-card-body uk-box-shadow-small uk-border-rounded uk-light in-card-orange">
            <div className="in-icon-wrap uk-margin-bottom">
              <i className="fas fa-handshake fa-lg" />
            </div>
            <h4 className="uk-margin-medium-top">
              <a href="#">Mutual fund advisor<i className="fas fa-chevron-right uk-float-right" /></a>
            </h4>
            <hr />
            <p>Specialized guidance from independent local advisor for hight-net-worth investors</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  <div className="uk-section">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-2-3@m">
          <div className="uk-grid uk-grid-small" data-uk-grid>
            <div className="uk-width-auto@m">
              <div className="in-icon-wrap large primary-color uk-margin-right">
                <i className="fas fa-money-bill-wave fa-2x" />
              </div>
            </div>
            <div className="uk-width-expand">
              <h3>Why Secure Funds With RUBIK STOCKS?</h3>
              <p>RUBIK STOCKS is backed up by FRA (Funds Retrieving Agent), this will enable you to withdraw all your money back after bank reset. You can only save your money, retirements saving on here by purchasing iso assets e.g XLM, XRP and some other assets.</p>
              <div className="uk-grid uk-child-width-1-1 uk-child-width-1-2@m">
                <div>
                  <ul className="uk-list in-list-check">
                    <li>Direct Market Access (DMA)</li>
                    <li>Leverage up to 1:500</li>
                    <li>T+0 settlement</li>
                    <li>Dividends paid in cash</li>
                  </ul>
                </div>
                <div className="in-margin-top-10@s in-margin-bottom-30@s">
                  <ul className="uk-list in-list-check">
                    <li>Free from UK Stamp Duty</li>
                    <li>Short selling available</li>
                    <li>Commissions from 0.08%</li>
                    <li>Access to 1500 global shares</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  <div className="uk-section">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-1-1 in-card-16">
          <div className="uk-card uk-card-default uk-card-body uk-box-shadow-small uk-border-rounded">
            <div className="uk-grid uk-flex-middle" data-uk-grid>
              <div className="uk-width-1-1 uk-width-expand@m">
                <h3>Get up to $50 Security Reward</h3>
              </div>
              <div className="uk-width-auto">
                <a className="uk-button uk-button-primary uk-border-rounded" href="https://secure.assetxledgers.com/">Open an Account</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



  )
}

export default Packages
