import React from 'react'

const Footer = () => {
  return (
    
   
<footer>
  <div className="uk-section uk-section-muted uk-padding-large uk-padding-remove-horizontal uk-margin-medium-top">
    <div className="uk-container">
      <div className="uk-grid-medium" data-uk-grid>
        <div className="uk-width-expand@m">
          <div className="footer-logo">
            <img className="uk-display-block" src="https://res.cloudinary.com/dg8pxsdme/image/upload/v1705294918/assetslegerblack_l1culd.png" alt="footer-logo" width={134} height={23} data-uk-img />
          </div>
          <p className="uk-text-large uk-margin-small-top">Secure your funds with ASSETS XLEDGERS
          </p>
         
        </div>
        <div className="uk-width-3-5@m">
          <div className="uk-child-width-1-3@s uk-child-width-1-3@m" data-uk-grid>
            <div>
              <h4><span>Pages</span></h4>
              <ul className="uk-list uk-link-text">
                <li><a href="/about">About</a></li>
                <li><a href="/markets">Markets</a></li>
              </ul>
            </div>
            <div>
              <h4><span>Resources</span></h4>
              <ul className="uk-list uk-link-text">
                <li><a href="https://secure.assetxledgers.com/register">Register</a></li>
                <li><a href="https://secure.assetxledgers.com/">Login</a></li>
              </ul>
            </div>
            <div>
              <h4><span>Company</span></h4>
              <ul className="uk-list uk-link-text">
                <li><a href="/contact">Contact</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1 uk-margin-large-top in-offset-bottom-10">
          <div className="uk-grid uk-flex uk-flex-middle" data-uk-grid>
            <div className="uk-width-1-2@m">
              <div className="uk-grid-small uk-flex uk-child-width-1-4@s uk-flex uk-child-width-1-5@m in-payment-method uk-text-center" data-uk-grid>
                <div>
                  <div className="uk-card uk-card-default uk-card-small uk-card-body">
                    <img src="img/in-lazy.gif" data-src="img/in-wave-visa.svg" alt="wave-payment" width={59} height={22} data-uk-img />
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-small uk-card-body">
                    <img src="img/in-lazy.gif" data-src="img/in-wave-mastercard.svg" alt="wave-payment" width={59} height={22} data-uk-img />
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-small uk-card-body">
                    <img src="img/in-lazy.gif" data-src="img/in-wave-skrill.svg" alt="wave-payment" width={59} height={22} data-uk-img />
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-small uk-card-body">
                    <img src="img/in-lazy.gif" data-src="img/in-wave-paypal.svg" alt="wave-payment" width={59} height={22} data-uk-img />
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-small uk-card-body uk-visible@m">
                    <img src="img/in-lazy.gif" data-src="img/in-wave-neteller.svg" alt="wave-payment" width={59} height={22} data-uk-img />
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@m uk-flex uk-flex-right@m">
              {/* social media begin */}
              <div className="uk-flex social-media-list">
                <div><a href="#" className="color-facebook text-decoration-none"><i className="fab fa-facebook-square" /> Facebook</a></div>
                <div><a href="#" className="color-twitter text-decoration-none"><i className="fab fa-twitter" /> Twitter</a></div>
                <div><a href="#" className="color-instagram text-decoration-none"><i className="fab fa-instagram" /> Instagram</a></div>
                <div><a href="#" className="color-youtube text-decoration-none"><i className="fab fa-youtube" /> Youtube</a></div>
              </div>
              {/* social media end */}
            </div>
          </div>
        </div>
      </div>
    </div>        
  </div>
  <div className="uk-section uk-section-secondary uk-padding-remove-vertical">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-3-4@m uk-visible@m">
          <ul className="uk-subnav uk-subnav-divider">
            <li><a href="#">Regulations</a></li>
            <li><a href="#">Legal documents</a></li>
            <li><a href="#">Important information</a></li>
            <li><a href="#">Privacy</a></li>
            <li><a href="#">Public relations</a></li>
            <li><a href="#">Careers</a></li>
          </ul>
        </div>
        <div className="uk-width-expand@m uk-text-right@m">
          <p className="copyright-text">© 2023 ASSETS XLEDGERS</p>
        </div>
      </div>
    </div>
  </div>
</footer>




  )
}

export default Footer
