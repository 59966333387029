import React from 'react'

const About = () => {
  return (
    <div>
  <div className="uk-section">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div className="uk-width-3-5@m">
            <h1 className="uk-margin-remove-bottom">Securing Assets <span className="in-highlight">For Clients</span></h1>
            <p className="uk-text-lead uk-text-muted uk-margin-small-top"> we’ve been empowering clients by helping them take control of their financial lives.</p>
          </div>
        </div>
        <div className="uk-grid uk-grid-large uk-child-width-1-3@m uk-margin-medium-top" data-uk-grid>
          <div className="uk-flex uk-flex-left">
            <div className="uk-margin-right">
              <div className="in-icon-wrap primary-color">
                <i className="fas fa-leaf fa-lg" />
              </div>
            </div>
            <div>
              <h3>Legal Company
              </h3>
              <p className="uk-margin-remove-bottom">Our company conducts absolutely legal activities in the legal field. We are certified to operate security business, we are legal and safe.</p>
            </div>
          </div>
          <div className="uk-flex uk-flex-left">
            <div className="uk-margin-right">
              <div className="in-icon-wrap primary-color">
                <i className="fas fa-hourglass-end fa-lg" />
              </div>
            </div>
            <div>
              <h3>High Reliability</h3>
              <p className="uk-margin-remove-bottom">Anonymity and using secure end to end encryption based USD as a payment instrument. In the era of electronic money – this is one of the most convenient ways of cooperation.</p>
            </div>
          </div>
          <div className="uk-flex uk-flex-left">
            <div className="uk-margin-right">
              <div className="in-icon-wrap primary-color">
                <i className="fas fa-flag fa-lg" />
              </div>
            </div>
            <div>
              <h3>Anonymity</h3>
              <p className="uk-margin-remove-bottom">Anonymity and using secure end to end encryption based USD as a payment instrument. In the era of electronic money – this is one of the most convenient ways of cooperation.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  <div className="uk-section">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-border-rounded uk-background-center uk-background-contain uk-background-image@m" style={{backgroundImage: 'url(img/blockit/in-team-background-1.png)', backgroundPositionY: 'calc(50% + -7.53px)'}} data-uk-parallax="bgy: -100">
            <div className="uk-card-body">
              <div className="uk-grid uk-flex uk-flex-center">
                <div className="uk-width-3-4@m uk-text-center">
                  <h2>Trust the Professionals</h2>
                  <p>We are a group of passionate, independent thinkers who never stop exploring new ways to improve trading for the self-directed investor.</p>
                </div>
              </div>
              <div className="uk-grid uk-child-width-1-2@m uk-margin-medium-top" data-uk-grid>
                <div className="uk-flex uk-flex-left uk-first-column">
                  <div className="uk-margin-right">
                    <img className="uk-align-center" src="img/blockit/in-team-1.png" alt="image-team" width={300} />
                  </div>
                  <div>
                    <p className="uk-text-small uk-text-muted uk-text-uppercase uk-margin-remove-bottom">Chief Executive Officer</p>
                    <h4 className="uk-margin-small-top">Cynthia Dixon</h4>
                    <div>
                      <a className="uk-link-muted" href="#"><i className="fab fa-facebook-f uk-margin-small-right" /></a>
                      <a className="uk-link-muted" href="#"><i className="fab fa-twitter uk-margin-small-right" /></a>
                      <a className="uk-link-muted" href="#"><i className="fab fa-linkedin-in" /></a>
                    </div>
                  </div>
                </div>
                <div className="uk-flex uk-flex-left">
                  <div className="uk-margin-right">
                    <img className="uk-align-center" src="img/blockit/in-team-2.png" alt="image-team" width={300} />
                  </div>
                  <div>
                    <p className="uk-text-small uk-text-muted uk-text-uppercase uk-margin-remove-bottom">Executive Assistant</p>
                    <h4 className="uk-margin-small-top">Arthur Parker</h4>
                    <div>
                      <a className="uk-link-muted" href="#"><i className="fab fa-facebook-f uk-margin-small-right" /></a>
                      <a className="uk-link-muted" href="#"><i className="fab fa-twitter uk-margin-small-right" /></a>
                      <a className="uk-link-muted" href="#"><i className="fab fa-linkedin-in" /></a>
                    </div>
                  </div>
                </div>
                <div className="uk-flex uk-flex-left uk-grid-margin uk-first-column">
                  <div className="uk-margin-right">
                    <img className="uk-align-center" src="img/blockit/in-team-3.png" alt="image-team" width={300} />
                  </div>
                  <div>
                    <p className="uk-text-small uk-text-muted uk-text-uppercase uk-margin-remove-bottom">Marketing Specialist</p>
                    <h4 className="uk-margin-small-top">Evelyn Mason</h4>
                    <div>
                      <a className="uk-link-muted" href="#"><i className="fab fa-facebook-f uk-margin-small-right" /></a>
                      <a className="uk-link-muted" href="#"><i className="fab fa-twitter uk-margin-small-right" /></a>
                      <a className="uk-link-muted" href="#"><i className="fab fa-linkedin-in" /></a>
                    </div>
                  </div>
                </div>
                <div className="uk-flex uk-flex-left uk-grid-margin">
                  <div className="uk-margin-right">
                    <img className="uk-align-center" src="img/blockit/in-team-4.png" alt="image-team" width={300} />
                  </div>
                  <div>
                    <p className="uk-text-small uk-text-muted uk-text-uppercase uk-margin-remove-bottom">Human Resources</p>
                    <h4 className="uk-margin-small-top">Bryan Greene</h4>
                    <div>
                      <a className="uk-link-muted" href="#"><i className="fab fa-facebook-f uk-margin-small-right" /></a>
                      <a className="uk-link-muted" href="#"><i className="fab fa-twitter uk-margin-small-right" /></a>
                      <a className="uk-link-muted" href="#"><i className="fab fa-linkedin-in" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content begin */}
  <div className="uk-section">
    <div className="uk-container">
      <div className="uk-grid uk-flex uk-flex-center">
        <div className="uk-width-3-4@m">
          <div className="uk-grid uk-flex uk-flex-middle" data-uk-grid>
            <div className="uk-width-1-2@m">
              <h4 className="uk-text-muted">Number speaks</h4>
              <h1 className="uk-margin-medium-bottom">We always ready<br />for a <span className="in-highlight">challenge.</span></h1>
              <a href="#" className="uk-button uk-button-primary uk-border-rounded">Learn more</a>
            </div>
            <div className="uk-width-1-2@m">
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={213}>213</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h4>Security instruments
                  </h4>
                  <p>Over 200+ trading instrument at our disposal</p>
                </div>
              </div>
              <div className="uk-margin-large" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <h1 className="uk-text-primary uk-text-right@m">
                    <span className="count" data-counter-end={27}>27</span>
                  </h1>
                  <hr className="uk-divider-small uk-text-right@m" />
                </div>
                <div className="uk-width-expand@m">
                  <h4>Countries covered</h4>
                  <p>Over $200k successful funds security in 20 different countries</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  )
}

export default About
