import React from 'react'

const Contact = () => {
  return (
    <div className="uk-section">
  <div className="uk-container">
    <div className="uk-grid">
      <div className="uk-width-3-5@m">
        <h1 className="uk-margin-remove-bottom">Contact Us</h1>
        <p className="uk-text-lead uk-text-muted uk-margin-small-top">Do not hesitate to reach out. Just fill in the contact form here and we’ll be sure to reply as fast as possible.</p>
      </div>
      <div className="uk-width-1-1@m uk-margin-large-top">
        <div className="uk-grid uk-grid-large" data-uk-grid>
          <div className="uk-width-1-3@m">
            <h4 className="uk-margin-medium-top uk-margin-remove-bottom">Message us</h4>
            <p className="uk-margin-small-top">admin@assetxledgers.com<br /></p>
            <div className="uk-margin-medium-top in-wave-socials-contact">
              <a href="#" className="fab fa-facebook-square fa-lg uk-text-decoration-none uk-margin-right" />
              <a href="#" className="fab fa-twitter fa-lg uk-text-decoration-none uk-margin-right" />
              <a href="#" className="fab fa-linkedin-in fa-lg uk-text-decoration-none uk-margin-right" />
              <a href="#" className="fab fa-instagram fa-lg uk-text-decoration-none uk-margin-right" />
              <a href="#" className="fab fa-pinterest fa-lg uk-text-decoration-none uk-margin-right" />
            </div>
          </div>
          <div className="uk-width-2-3@m">
            <div className="uk-margin-medium-left in-margin-remove-left@s">
              <form id="contact-form" className="uk-form uk-grid-small" data-uk-grid>
                <div className="uk-width-1-2@s uk-inline">
                  <span className="uk-form-icon fas fa-user fa-sm" />
                  <input className="uk-input uk-border-rounded" id="name" name="name" type="text" placeholder="Full name" />
                </div>
                <div className="uk-width-1-2@s uk-inline">
                  <span className="uk-form-icon fas fa-envelope fa-sm" />
                  <input className="uk-input uk-border-rounded" id="email" name="email" type="email" placeholder="Email address" />
                </div>
                <div className="uk-width-1-1 uk-inline">
                  <span className="uk-form-icon fas fa-pen fa-sm" />
                  <input className="uk-input uk-border-rounded" id="subject" name="subject" type="text" placeholder="Subject" />
                </div>
                <div className="uk-width-1-1">
                  <textarea className="uk-textarea uk-border-rounded" id="message" name="message" rows={6} placeholder="Message" defaultValue={""} />
                </div>
                <div className="uk-width-1-1">
                  <button className="uk-button uk-button-primary uk-border-rounded uk-align-right" id="sendemail" type="submit" name="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  )
}

export default Contact
