import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Index from './components/Home/Index';
import { Routes, Route } from "react-router-dom";
import About from './pages/About';
import Affiliate from './pages/Affiliate';
import Packages from './pages/Packages';
import Contact from './pages/Contact';
function App() {
 
  return (
    <div className="App">
      {/* <Header/> */}
      <Routes>
        <Route path='/' element={<Index/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/affiliate' element={<Affiliate/>} />
        <Route path='/markets' element={<Packages/>} />
        <Route path='/contact' element={<Contact/>} />       
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
