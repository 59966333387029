import React from 'react'

const Index = () => {
  return (
    <div>
  <div  className="uk-section uk-padding-remove-vertical in-slideshow-gradient">
    <div className="in-slideshow" data-uk-slideshow>
      <ul className="uk-slideshow-items uk-light" style={{height: '480px'}}>
        <li>
          <div className="uk-position-cover" >
            <img  src="img/in-lazy.gif" data-src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1696244789/in-slideshow-image-1.jpg" alt="slideshow-image" data-uk-cover width={1920} height={700}  data-uk-img />
          </div>                
          <span />
          <div className="uk-container">
            <div className="uk-grid" data-uk-grid>
              <div className="uk-width-3-5@m">
                <div className="uk-overlay">
                  <h1>Keep Your Assets Safe on ASSETS XLEDGERS</h1>
                  <p className="uk-text-lead uk-visible@m">Unlimited daily transactions with multi level security</p>
                  <a href="https://secure.assetxledgers.com/" className="uk-button uk-button-primary uk-border-rounded uk-visible@m"><i className="fas fa-scroll uk-margin-small-right" />Register Now</a>
                </div>
              </div>
            </div>
          </div>
        </li> <li>
          <div className="uk-position-cover">
            <img src="img/in-lazy.gif" data-src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1696244788/in-slideshow-image-2.jpg" alt="slideshow-image" data-uk-cover width={1920} height={700} data-uk-img />
          </div>                
          <span />
          <div className="uk-container">
            <div className="uk-grid" data-uk-grid>
              <div className="uk-width-3-5@m">
                <div className="uk-overlay">
                  <h1>Say No To Dollar Crash</h1>
                  <p className="uk-text-lead uk-visible@m">A trusted destination for traders worldwide, Authorised by FCA, ASIC &amp; FSCA with multi-lingual support 24/5.</p>
                  <a href="https://secure.assetxledgers.com/" className="uk-button uk-button-primary uk-border-rounded uk-visible@m"><i className="fas fa-scroll uk-margin-small-right" />Get Started</a>
                </div>
              </div>
            </div>
          </div>
        </li> <li>
          <div className="uk-position-cover">
            <img src="img/in-lazy.gif" data-src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1696244781/in-slideshow-image-3.jpg" alt="slideshow-image" data-uk-cover width={1920} height={700} data-uk-img />
          </div>                
          <span />
          <div className="uk-container">
            <div className="uk-grid" data-uk-grid>
              <div className="uk-width-3-5@m">
                <div className="uk-overlay">
                  <h1>Award-winning Assets Management and Security platform</h1>
                  <p className="uk-text-lead uk-visible@m">Tap into the world's most secured network and explore endless trading opportunities with tight spreads and no commission.</p>
                  <a href="https://secure.assetxledgers.com/" className="uk-button uk-button-primary uk-border-rounded uk-visible@m"><i className="fas fa-scroll uk-margin-small-right" />Create Account</a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="uk-container uk-light">
        <ul className="uk-slideshow-nav uk-dotnav uk-position-bottom-center" />
      </div>
    </div>
  </div>
  {/* slideshow content end */}
  {/* section content begin */}
  <div className="uk-section uk-section-primary uk-padding-remove-vertical in-wave-1">
    <div className="uk-container">
      <div className="uk-grid uk-grid-divider uk-child-width-1-2@s uk-child-width-1-4@m in-margin-top@s in-margin-bottom@s" data-uk-grid>
        <div>
          <div className="uk-grid uk-grid-small uk-flex uk-flex-middle">
            <div className="uk-width-auto">
              <img src="img/in-lazy.gif" data-src="img/in-wave-icon-1.svg" alt="wave-icon" width={48} height={48} data-uk-img />
            </div>
            <div className="uk-width-expand">
              <p>Free<br />analysis tools</p>
            </div>
          </div>
        </div>
        <div>
          <div className="uk-grid uk-grid-small uk-flex uk-flex-middle">
            <div className="uk-width-auto">
              <img src="img/in-lazy.gif" data-src="img/in-wave-icon-2.svg" alt="wave-icon" width={48} height={48} data-uk-img />
            </div>
            <div className="uk-width-expand">
              <p>Fast execution<br />0 commision</p>
            </div>
          </div>
        </div>
        <div>
          <div className="uk-grid uk-grid-small uk-flex uk-flex-middle">
            <div className="uk-width-auto">
              <img src="img/in-lazy.gif" data-src="img/in-wave-icon-3.svg" alt="wave-icon" width={48} height={48} data-uk-img />
            </div>
            <div className="uk-width-expand">
              <p>Low minimum<br />deposit of $100</p>
            </div>
          </div>
        </div>                        
        <div>
          <div className="uk-grid uk-grid-small uk-flex uk-flex-middle">
            <div className="uk-width-auto">
              <img src="img/in-lazy.gif" data-src="img/in-wave-icon-4.svg" alt="wave-icon" width={48} height={48} data-uk-img />
            </div>
            <div className="uk-width-expand">
              <p>Over 2,100<br />assets to trade</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  <div className="uk-section in-wave-2">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-3-4@m">
          <h1 className="uk-margin-remove-bottom">Industry-<span className="in-highlight">leading</span> Funds Security</h1>
          <p className="uk-text-lead uk-text-muted uk-margin-small-top uk-margin-bottom">ASSETS XLEDGERS is backed up by FRA (Funds Retrieving Agent), this will enable you to withdraw all your money back after bank reset. You can only save your money, retirements saving on here by purchasing iso assets e.g XLM, XRP and some other assets.
          </p>
        </div>
      </div>
      <div className="uk-grid-medium uk-grid-match" data-uk-grid>
        <div className="uk-width-1-2@s uk-width-1-4@m">
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-background-contain uk-background-bottom-center" style={{backgroundImage: 'url(img/in-wave-card-bg-1.png)'}}>
            <h5 className="uk-margin-remove">
              <a href="#">FX <i className="fas fa-chevron-right fa-xs" /></a>
            </h5>
            <p className="uk-margin-remove">Spread as low as</p>
            <h1 className="uk-margin-top">0.2</h1>
            <p className="uk-margin-remove-top uk-margin-bottom">pip</p>
            <p>Trade 182 FX spot pairs and 140 forwards across majors, minors, exotics and metals.</p>
          </div>
        </div>
        <div className="uk-width-1-2@s uk-width-1-4@m">
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-background-contain uk-background-bottom-center" style={{backgroundImage: 'url(img/in-wave-card-bg-2.png)'}}>
            <h5 className="uk-margin-remove">
              <a href="#">CFDs <i className="fas fa-chevron-right fa-xs" /></a>
            </h5>
            <p className="uk-margin-remove">Spread from</p>
            <h1 className="uk-margin-top">0.4</h1>
            <p className="uk-margin-remove-top uk-margin-bottom">on US500</p>
            <p>Go long or short on 9,000+ instruments with tight spreads &amp; low commissions.</p>
          </div>
        </div>
        <div className="uk-width-1-2@s uk-width-1-4@m">
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-background-contain uk-background-bottom-center" style={{backgroundImage: 'url(img/in-wave-card-bg-3.png)'}}>
            <h5 className="uk-margin-remove">
              <a href="#">Stocks <i className="fas fa-chevron-right fa-xs" /></a>
            </h5>
            <p className="uk-margin-remove">Commissions from</p>
            <h1 className="uk-margin-top">$3</h1>
            <p className="uk-margin-remove-top uk-margin-bottom">on US stocks</p>
            <p>Access 19,000+ stocks across core and emerging markets on 36 exchanges worldwide.</p>
          </div>
        </div>
        <div className="uk-width-1-2@s uk-width-1-4@m">
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-background-contain uk-background-bottom-center" style={{backgroundImage: 'url(img/in-wave-card-bg-4.png)'}}>
            <h5 className="uk-margin-remove">
              <a href="#">Commodities <i className="fas fa-chevron-right fa-xs" /></a>
            </h5>
            <p className="uk-margin-remove">Commission as low as</p>
            <h1 className="uk-margin-top">$1.25</h1>
            <p className="uk-margin-remove-top uk-margin-bottom">per lot</p>
            <p>Trade a wide range of commodities as CFDs, futures, options, spot pairs, &amp; more.</p>
          </div>
        </div>
      </div>
      <div className="uk-grid uk-flex uk-flex-center">
        <div className="uk-width-3-5@m">
          <div className="uk-card uk-card-default uk-card-body uk-text-center uk-border-rounded uk-box-shadow-small in-wave-2-card">
            <span className="uk-label in-label-small uk-text-uppercase uk-margin-small-right">Start trade</span>
            Explore the markets at your own pace <a href="https://secure.assetxledgers.com/">Join now!</a>
          </div>
          <div className="uk-grid-collapse uk-grid-divider uk-child-width-1-3@s uk-child-width-1-3@m uk-text-center uk-margin-top uk-margin-small-bottom" data-uk-grid>
            <div>
              <i className="fas fa-headset fa-lg uk-margin-small-right uk-text-primary" />
              <p className="uk-margin-remove uk-text-small uk-text-uppercase">Award-winning support</p>
            </div>
            <div>
              <i className="fas fa-university fa-lg uk-margin-small-right uk-text-primary" />
              <p className="uk-margin-remove uk-text-small uk-text-uppercase">Regulated by the FCA,UK</p>
            </div>
            <div>
              <i className="fas fa-history fa-lg uk-margin-small-right uk-text-primary" />
              <p className="uk-margin-remove uk-text-small uk-text-uppercase">30 years experience</p>
            </div>
          </div>
        </div>
      </div>        
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  <div className="uk-section uk-section-muted uk-background-contain uk-background-center in-wave-3" style={{backgroundImage: 'url(img/in-wave-background-1.png)'}} data-uk-parallax="bgy: -200">
    <div className="uk-container">
      <div className="uk-grid-large uk-flex uk-flex-middle" data-uk-grid>
        <div className="uk-width-1-2@m">
          <img className="uk-margin-bottom" src="img/in-lazy.gif" data-src="img/in-wave-icon-5.svg" alt="wave-icon" width={64} height={64} data-uk-img />
          <h1 className="uk-margin-remove">Market <span className="in-highlight">analysis</span> and<br />trade inspiration</h1>
          <p>During the bank crash It will be shut down and Money in there stolen and never return again, as Joe Biden is destroying the dollar. Bank reset will affect many countries especially in the USA🇺🇸 , Canada 🇨🇦 and Australia 🇦🇺 .</p>
          <div className="uk-grid-medium uk-child-width-1-2@s uk-child-width-1-3@m uk-text-center uk-margin-medium-top" data-uk-grid>
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                <img className="uk-margin-remove-bottom" src="img/in-lazy.gif" data-src="img/in-wave-icon-6.svg" alt="wave-icon" width={52} height={52} data-uk-img />
                <h5 className="uk-margin-small-top">Strategies &amp; Discussions</h5>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                <img className="uk-margin-remove-bottom" src="img/in-lazy.gif" data-src="img/in-wave-icon-7.svg" alt="wave-icon" width={52} height={52} data-uk-img />
                <h5 className="uk-margin-small-top">Forecasts &amp; Educations</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-2@m">
          <div className="uk-inline uk-dark in-wave-video uk-margin-small-bottom">
            <img className="uk-border-rounded uk-width-1-1" src="img/in-lazy.gif" data-src="img/in-wave-image-1.jpg" alt="wave-video" width={533} height={355} data-uk-img />
            <div className="uk-position-center" data-uk-lightbox="video-autoplay: true;">
              <a href="https://www.youtube.com/watch?v=F3QpgXBtDeo" data-attrs="width: 920; height: 517;">
                <div className="in-play-button" />
                <i className="fas fa-play" />
              </a>
            </div>
            <div className="uk-flex-top" data-uk-modal>
              <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical in-iframe">
                <button className="uk-modal-close-outside" type="button" data-uk-close />
              </div>
            </div>
          </div>
          <p className="uk-text-small uk-text-muted">Explore the markets at your own pace with short online courses. <span className="uk-label in-label-small">Sign up</span></p>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  <div className="uk-section in-wave-4">
    <div className="uk-container">
      <div className="uk-grid uk-flex uk-flex-center">
        <div className="uk-width-1-1 uk-text-center">
          <h1 className="uk-margin-medium-bottom"><span className="in-highlight">Award-winning</span> Platform For Traders and Investors </h1>
        </div>
        
        <div className="uk-width-1-1">
          <div className="uk-grid-medium uk-child-width-1-2@s uk-child-width-1-5@m uk-text-center uk-margin-large-top" data-uk-grid>
            <div>
              <img src="img/in-lazy.gif" data-src="img/in-wave-award.svg" alt="wave-award" width={71} height={58} data-uk-img />
              <h6 className="uk-margin-small-top uk-margin-remove-bottom">Best CFD Broker</h6>
              <p className="uk-text-small uk-margin-remove-top">TradeON Summit 2020</p>
            </div>
            <div>
              <img src="img/in-lazy.gif" data-src="img/in-wave-award.svg" alt="wave-award" width={71} height={58} data-uk-img />
              <h6 className="uk-margin-small-top uk-margin-remove-bottom">Best Trading Experience</h6>
              <p className="uk-text-small uk-margin-remove-top">Jordan Forex EXPO 2020</p>
            </div>
            <div>
              <img src="img/in-lazy.gif" data-src="img/in-wave-award.svg" alt="wave-award" width={71} height={58} data-uk-img />
              <h6 className="uk-margin-small-top uk-margin-remove-bottom">Best Execution Broker</h6>
              <p className="uk-text-small uk-margin-remove-top">Forex EXPO Dubai 2020</p>
            </div>
            <div>
              <img src="img/in-lazy.gif" data-src="img/in-wave-award.svg" alt="wave-award" width={71} height={58} data-uk-img />
              <h6 className="uk-margin-small-top uk-margin-remove-bottom">Best Trading Platform</h6>
              <p className="uk-text-small uk-margin-remove-top">London Summit 2020</p>
            </div>
            <div className="uk-visible@m">
              <img src="img/in-lazy.gif" data-src="img/in-wave-award.svg" alt="wave-award" width={71} height={58} data-uk-img />
              <h6 className="uk-margin-small-top uk-margin-remove-bottom">Best Broker Asia</h6>
              <p className="uk-text-small uk-margin-remove-top">iFX EXPO 2020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* section content end */}
  {/* section content begin */}
  <div className="uk-section uk-section-default uk-padding-remove-vertical in-wave-5 in-offset-bottom-30">
    <div className="uk-container">
      <div className="uk-grid">
        <div className="uk-width-1-1 uk-background-contain uk-background-center-right uk-background-image@s" style={{backgroundImage: 'url(img/in-wave-mockup-1.png)'}}>
          <h1 className="uk-margin-remove-bottom">Start <span className="uk-text-primary">Securing funds</span></h1>
          <p className="uk-text-lead">on <span className="uk-text-primary">ASSETS XLEDGERS</span> mobile app.</p>
          <p className="uk-margin-small-bottom">Available on multiple platform</p>
          <div className="uk-flex">
            <a href="#" className="uk-button in-button-app">
              <i className="fab fa-google-play" />
              <span className="wrapper">Download from<br /><span>Play Store</span></span>
            </a>
            <a href="#" className="uk-button in-button-app uk-margin-small-left">
              <i className="fab fa-apple" />
              <span className="wrapper">Download from<br /><span>App Store</span></span>
            </a>
            <a href="#" className="uk-button in-button-app uk-margin-small-left uk-visible@m">
              <i className="fab fa-microsoft" />
              <span className="wrapper">Download from<br /><span>Microsoft Store</span></span>
            </a>
          </div>
        </div>
        <div className="uk-width-1-1 in-price-list">
          <div data-uk-slider="autoplay: true; autoplay-interval: 5000">
            <ul className="uk-slider-items uk-child-width-1-3@s uk-child-width-1-5@m uk-text-small uk-text-center" data-uk-grid>
              <li>
                <span>XAUUSD</span>
                <span className="uk-label uk-label-success uk-border-pill uk-margin-small-left"><i className="fas fa-caret-up" /> 1478.81 &nbsp;(+0.28%)</span>
              </li>
              <li>
                <span>GBPUSD</span>
                <span className="uk-label uk-label-danger uk-border-pill uk-margin-small-left"><i className="fas fa-caret-down" /> 1.3191 &nbsp;(-1.07%)</span>
              </li>
              <li>
                <span>EURUSD</span>
                <span className="uk-label uk-label-danger uk-border-pill uk-margin-small-left"><i className="fas fa-caret-down" /> 1.1159 &nbsp;(-0.11%)</span>
              </li>
              <li>
                <span>USDJPY</span>
                <span className="uk-label uk-label-success uk-border-pill uk-margin-small-left"><i className="fas fa-caret-up" /> 109.59 &nbsp;(+0.05%)</span>
              </li>
              <li>
                <span>USDCAD</span>
                <span className="uk-label uk-label-success uk-border-pill uk-margin-small-left"><i className="fas fa-caret-up" /> 1.3172 &nbsp;(+0.18%)</span>
              </li>
              <li>
                <span>USDCHF</span>
                <span className="uk-label uk-label-success uk-border-pill uk-margin-small-left"><i className="fas fa-caret-up" /> 0.9776 &nbsp;(+0.06%)</span>
              </li>
              <li>
                <span>AUDUSD</span>
                <span className="uk-label uk-label-danger uk-border-pill uk-margin-small-left"><i className="fas fa-caret-down" /> 0.67064 &nbsp;(-0.08%)</span>
              </li>
              <li>
                <span>GBPJPY</span>
                <span className="uk-label uk-label-success uk-border-pill uk-margin-small-left"><i className="fas fa-caret-up" /> 141.91 &nbsp;(+0.12%)</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Index
